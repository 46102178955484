import { Autocomplete, GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { ThemeContext } from '../../..';
import { ApiService } from '../../../service/api.service';
import { BookingFormSection, BookingFormSectionProps } from '../section';
import { MapStyle } from './maps.style';

const MAP_LIBS: Libraries = ['places'];
let directionsService: google.maps.DirectionsService;
interface ServiceList {
    id: string,
    title: string,
}

export let pickUp: google.maps.places.PlaceResult = undefined!;


export function BookingForm(props: BookingFormSectionProps) {
    const [disable, setDisable] = useState<boolean>(true);
    const {register} = props;
    let pickupAutocompleteRef = useRef<Autocomplete>(null);

    const [center, setCenter] = useState<google.maps.LatLng>();
    const [zoom, setZoom] = useState(10);
    const [map, setMap] = useState<google.maps.Map<Element>>()
    const [pickupPlace, setPickupPlace] = useState<google.maps.places.PlaceResult>(undefined!);
    const [serviceId, setServiceId] = useState<string>('');
    const [options, setOptions] = useState<any[]>([]);
    const [zoneCurrent, setZoneCurrent] = useState<any>()
    const [generalCurrency, setGeneralCurrency] = useState<any>()

    const theme = useContext(ThemeContext)

    

    useEffect(() => {
        pickupPlace ? setDisable(false) : setDisable(true);
    }, [disable, pickupPlace])

    const onLoad = React.useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
                setCenter(location)
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode({ location }, (results, status) => {
                    if (status === 'OK' || !results.length) {
                        const address = results[0];
                        let placeService = new google.maps.places.PlacesService(map as google.maps.Map<Element>);
                        placeService.getDetails({ placeId: address.place_id }, (placeResult, placeServiceStatus) => {
                            setPickupPlace(placeResult)
                            pickUp = placeResult
                        });

                        console.log("pickup",pickupPlace);
                    } else {
                        console.log('Failed to geocode current location', status);
                    }
                })
            });
        } else {
            alert('Geolocation is not supported by this browser');
        }

        setTimeout(() => {
            const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
            setCenter(DEFAULT_CENTER);
            setZoom(12);
        }, 100);

        directionsService = new google.maps.DirectionsService();

        pickupAutocompleteRef.current?.state.autocomplete?.addListener('place_changed', () => onAddressSelect());

    }, [])

    const onUnmount = React.useCallback(() => {
        setMap(undefined)
    }, []);

    


    const onAddressSelect = () => {
        const ref = pickupAutocompleteRef;
        const autocomplete: google.maps.places.Autocomplete | null | undefined = ref.current?.state.autocomplete;
        if (!autocomplete) {
            return;
        }

        pickUp = autocomplete.getPlace();
        setPickupPlace(pickUp)
    }

    const ondragend = (event: any) => {
        let newLat = event.latLng.lat();
        let newLng = event.latLng.lng();
        const location = new google.maps.LatLng(newLat, newLng)
        setCenter(location)
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ location }, (results, status) => {
            if (status === 'OK' || !results.length) {
                const address = results[0];
                let placeService = new google.maps.places.PlacesService(map as google.maps.Map<Element>);
                placeService.getDetails({ placeId: address.place_id }, (placeResult, placeServiceStatus) => {
                    setPickupPlace(placeResult)
                    pickUp = placeResult
                });
            } else {
                console.log('Failed to geocode current location', status);
            }
        })
    }

    useEffect(() => {
        refresh();
        if (pickupPlace) {
            setCenter(pickupPlace.geometry?.location);
            setZoom(18);
        }
    }, [pickupPlace])

    const refresh = async () => {
        try {
            const response = await ApiService.get('service/public/list');
            const data: ServiceList[] = response.data.map((item: any) => ({ id: item.id, title: item.title }));
            setOptions(data);
            setServiceId(data[0].id)
        } catch (err) {
            console.log('Failed to load fleet list', err);
        }
    }

    const onChange = (e: any) => {
        setServiceId(`${e.target.value}`);
    }


    return (
        <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`} libraries={MAP_LIBS}>
            <BookingFormSection {...props}>
                <>
                    <h4>Please enter your pickup location to complete your ride booking</h4>
                    <p>Please allow this page to retrieve your location or enter your location above.</p>
                    <Autocomplete
                        ref={pickupAutocompleteRef}
                        // restrictions={{ country: ['pak'] }}
                        fields={['geometry',"address_component","formatted_address"]}
                        {...register("test 1")}
                    >
                        <input {...register("test 1")} autoFocus className="form-control bg-white" placeholder="Pickup" type="text" />
                    </Autocomplete>
                    <div className="select inputshow">
                        <select className="form-control bg-white" onChange={onChange} placeholder='service'>
                            {options.map((item) =>
                                <option key={item.id} className="form-control bg-white" value={item.id}>{item.title}</option>
                            )}
                        </select>
                    </div>
                    {
                        theme === process.env.REACT_APP_TAXIS_PLUS_THEME ? <div className={(pickupPlace ? 'main-map' : '')}>
                            <div className={'input' + (pickupPlace ? 'show' : '')}>
                                <GoogleMap
                                    mapContainerStyle={{ width: '100%', height: 500 }}
                                    center={center}
                                    zoom={zoom}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                >
                                    {pickupPlace && pickupPlace.geometry && <Marker draggable={true} onDragEnd={ondragend} position={pickupPlace.geometry.location} />}
                                </GoogleMap>
                            </div>
                        </div> :
                            <div className={'input' + (pickupPlace ? 'show' : '')}>
                                <GoogleMap
                                    options={{ styles: MapStyle }}
                                    mapContainerStyle={{ width: '100%', height: 500 }}
                                    center={center}
                                    zoom={zoom}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                >
                                    {pickupPlace && pickupPlace.geometry && <Marker draggable={true} onDragEnd={ondragend} position={pickupPlace.geometry.location} />}
                                </GoogleMap>
                            </div>
                    }
                    {theme === process.env.REACT_APP_COOL_RIDE_THEME ?
                        <input
                            value='Next'
                            type='submit'
                            className="button"
                            disabled={disable}
                        /> :
                        <input
                            value='Next'
                            type='submit'
                            className="texisPlusBtn"
                            disabled={disable}
                        />
                    }
                </>
            </BookingFormSection>
         </LoadScript>
    )
}