import React, { BaseSyntheticEvent, FormEvent, ReactElement, useContext, useEffect, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { ThemeContext } from '../..';
import { BookingForm } from './section/booking';
import { DropOffForm } from './section/dropOff';
import { PassengerForm } from './section/passenger.input';

const coolRideLogo = require(`../../css/img/${process.env.REACT_APP_COOL_RIDE_LOGO}`);
const taxisPlusLogo = require(`../../css/img/${process.env.REACT_APP_TAXIS_PLUS_LOGO}`);

export interface BookingStep {
    title: string;
    view: BookingViewState;
    completed?: boolean;
}

export enum BookingViewState {
    Booking,
    DropOff,
    Passenger,
}

const STEPS: BookingStep[] = [
    {
        title: 'Booking',
        view: BookingViewState.Booking,
    },
    {
        title: 'DropOff',
        view: BookingViewState.DropOff,
    },
    {
        title: 'Passenger',
        view: BookingViewState.Passenger
    }
];

export function UserBookingRegister(props: any) {
  


    const formProps = useForm();

    const [view, setView] = useState(BookingViewState.Booking);
    const [steps, setSteps] = useState(STEPS);
    const [value, setValue] = useState(null);

    const [errorMessage, setErrorMessage] = useState<string>();
    const theme = useContext(ThemeContext)

    

    const renderForm = (): ReactElement =>
        <>
       
            <BookingForm  show={view === BookingViewState.Booking} {...formProps} />
            <DropOffForm setData={setValue} show={view === BookingViewState.DropOff} {...formProps} />
             <PassengerForm data={value} show={view === BookingViewState.Passenger} {...formProps} />
            
        </>

    const handleNext = () => {
        nextStep();
    }

    const handleError = (errors: FieldErrors, e?: BaseSyntheticEvent) => {
        const currentStep = steps.find(step => step.view === view);
        const errorMessages: string[] = [];
        if (errorMessages.length > 0) {
            setErrorMessage(errorMessages.join(', '))
            return;
        } else {
            setErrorMessage(undefined);
            handleNext();
        }
    }

    const nextStep = (e?: FormEvent<HTMLFormElement>) => {
        console.log("form Event",e);
        const currentStepIndex = steps.findIndex(step => step.view === view);
        if (currentStepIndex === -1) {
            throw new Error('While next stepping operator form, step missing for view');
        }

        // Mark current step as completed
        const currentStep = steps[currentStepIndex];
        currentStep.completed = true;
        const updatedSteps = [...steps];
        updatedSteps[currentStepIndex] = currentStep;
        setSteps(updatedSteps);

        const nextStep = steps[currentStepIndex + 1];
        // If we're on the last step, submit the form
        if (!nextStep) {
            console.log('Submitting form')
            console.log('Values', formProps.getValues())
            submit();
            // props.history.push('/payment')
        } else {
            console.log('Values', formProps.getValues())
            setErrorMessage(undefined);
            setView(nextStep.view);
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        e && e.preventDefault()

        return false;
    }

    const setStep = (step: BookingStep) => {
        setView(step.view);
    }

    const submit = async () => {
        setErrorMessage(undefined);
        try {
            const data = formProps.getValues()
            console.log('data ka', data)
        } catch (err) {
            setErrorMessage(err?.message);
        }
    }



    return (
        <>
            <div className="container text-center" >
                <div className="container text-center" >
                    {theme === process.env.REACT_APP_COOL_RIDE_THEME ?
                        <div>
                            <img src={coolRideLogo} alt="coolride" width="45%"></img>
                            <h4>Thank you for using CoolRide!</h4>
                        </div> :
                        <div style={{ marginTop: '70px', marginBottom: '10px' }}>
                            <img style={{ marginBottom: '30px' }} src={taxisPlusLogo} alt="coolride" width="45%"></img>
                            <h4>Thank you for using Taxis.Plus</h4>
                        </div>
                    }
                </div>
                <form onSubmit={formProps.handleSubmit(handleNext, handleError)}>
                    {errorMessage && <div className="error">{errorMessage}</div>}
                  {renderForm()}
                    
                    
                </form>
            </div>
        </>
    )
}