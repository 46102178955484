import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { ThemeContext } from "../../.."
import { ApiService } from "../../../service/api.service";

const coolRideLogo = require(`../../../css/img/${process.env.REACT_APP_COOL_RIDE_LOGO}`);
const taxisPlusLogo = require(`../../../css/img/${process.env.REACT_APP_TAXIS_PLUS_LOGO}`);


export default function PaymentForm(props:any) {
    const [success, setSuccess] = useState(false)
    const stripe = useStripe()
    const elements = useElements()
    const theme = useContext(ThemeContext)
    const [stripCustomerId, setStripeCustomerId] = useState<string>("");
    const location:any = useLocation();

    useEffect(()=>{
        console.log("pro1",props?.location?.state)
    })
    const OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    }

  
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        console.log("response from payment 0",location)
        const loc = JSON.parse(location.state.stateData)
        console.log("response from payment 0.5",loc)
        console.log("response from payment 1",loc.updatedData.pickup.lat)
        let data:any = JSON.parse(loc.response.config.data)
        let resData:any = {};
        resData.phone = loc.passenger.phone
        resData.estimate = loc.response.data
        resData.zone = loc.updatedData.zoneId
        resData.pickupAddress = loc.updatedData.pickup
        resData.dropOffAddress = loc.updatedData.dropoff
        resData.distance = data.distance
        resData.service = data.serviceId
        resData.duration= data.duration
        console.log("response from payment 2",resData)



         const payload = await stripe?.createPaymentMethod({
            type: "card",
            card: elements?.getElement(CardElement)!,
            billing_details: {
                name: e.target.name.value
            }
        })


        if (!payload?.error) {
            try {
                const id = payload?.paymentMethod
                console.log('id:', id)
                setSuccess(true)
            } catch (error) {
                console.log("Error", error)
            }
        } else {
            console.log(payload.error.message)
        }
        
         const response = await ApiService.post('booking/passengerSave',resData);
         console.log("response from payment",response)
       
    }

    return (
        <>
            <div className="container text-center" >
                <div className="container text-center" >
                    {theme === process.env.REACT_APP_COOL_RIDE_THEME ?
                        <div>
                            <img src={coolRideLogo} alt="coolride" width="45%"></img>
                            <h4>Thank you for using CoolRide!</h4>
                            <h4>Please enter a payment method to finalize your booking</h4>
                        </div> :
                        <div style={{ marginTop: '70px', marginBottom: '10px' }}>
                            <img style={{ marginBottom: '30px' }} src={taxisPlusLogo} alt="coolride" width="45%"></img>
                            <h4>Thank you for using Taxis.Plus</h4>
                            <h4>Please enter a payment method to finalize your booking</h4>
                        </div>
                    }
                </div>
                {!success ?
                    <form onSubmit={handleSubmit}>
                        <input className="form-control bg-white inputshow" placeholder="Name on Card" type="text" name='name' />
                        <fieldset className="FormGroup inputshow">
                            <div className="FormRow">
                                <CardElement options={OPTIONS} className="form-control bg-white" />
                            </div>
                        </fieldset>
                        {theme === process.env.REACT_APP_COOL_RIDE_THEME ?
                            <button className='button'>Confirm Booking</button> :
                            <button className='texisPlusBtn'>Confirm Booking</button>
                        }
                    </form>
                    :
                    <div>
                        <h2>Your booking has been made. A driver will be there to pick you up shortly</h2>
                    </div>
                }
            </div>

        </>
    )
}