import { Autocomplete, DirectionsRenderer, GoogleMap, LoadScript, Marker, TrafficLayer } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import { add, formatDuration, intervalToDuration } from 'date-fns';
import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import { ThemeContext } from '../../..';
import { ApiService } from '../../../service/api.service';
import { BookingFormSection, BookingFormSectionProps } from '../section';
import { pickUp } from './booking';
import { MapStyle } from './maps.style';

const MAP_LIBS: Libraries = ['places'];
let directionsService: google.maps.DirectionsService;
interface ServiceList {
    id: string,
    title: string,
}

export const Context = React.createContext({ value: null});


export function DropOffForm(props: BookingFormSectionProps) {
    let dropoffAutocompleteRef = useRef<Autocomplete>(null);

    const [center, setCenter] = useState<google.maps.LatLng>();
    const [zoom, setZoom] = useState(10);
    const [, setMap] = useState<google.maps.Map>()
    const [dropoffPlace, setDropoffPlace] = useState<google.maps.places.PlaceResult>();
    const [directions, setDirections] = useState<google.maps.DirectionsResult>();

    const [distance, setDistance] = useState<number>();
    const [duration, setDuration] = useState<number>();
    const [trafficDuration, setTrafficDuration] = useState<number>();
    const [estimate, setEstimate] = useState<number>();
    const [serviceId, setServiceId] = useState<string>('');
    const [route, setRoute] = useState<any>();
    const [currency,setCurrency] = useState<any>();
    const [subunit,setSubunit] = useState<any>();


    const theme = useContext(ThemeContext)


    const onLoad = React.useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)

        setTimeout(() => {
            const DEFAULT_CENTER = new google.maps.LatLng(-33.8688, 151.2093);
            setCenter(DEFAULT_CENTER);
            setZoom(12);
        }, 100);

        directionsService = new google.maps.DirectionsService();

        dropoffAutocompleteRef.current?.state.autocomplete?.addListener('place_changed', () => onAddressSelect());

    }, [])

    const onUnmount = React.useCallback(() => {
        setMap(undefined)
    }, []);

    const onAddressSelect = () => {
        const ref = dropoffAutocompleteRef;
        const autocomplete: google.maps.places.Autocomplete | null | undefined = ref.current?.state.autocomplete;
        if (!autocomplete) {
            return;
        }

        const setter = setDropoffPlace;
        setter(autocomplete.getPlace());
    }

    const {value} = useContext(Context);
    const loadEstimate = async () => {
        
        const response:any = await ApiService.post('fare/estimate', {
            lat: pickUp?.geometry?.location.lat(),
            lng: pickUp?.geometry?.location.lng(),
            distance,
            duration,
            traffic: trafficDuration,
            serviceId,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });

        const {data}:any = await await ApiService.post('fare/getZone',{lat:pickUp?.geometry?.location.lat() as number,lng:pickUp?.geometry?.location.lng() as number});
        if(data){

            setCurrency(data.generalCurrency.symbol)
            setSubunit(data.generalCurrency.subunits)   
            console.log("data in dropOff",data); 
                    
        }

        
        


        const areaTypes = ['locality', 'sublocality_level_1', 'sublocality', 'political'];
        const areaPickup = pickUp?.address_components?.find((component:any) => {
            for (let areaType of areaTypes) {
                if (component.types.includes(areaType)) {
                return true;
                }
            }
            return false;
            });

            console.log("Area from pickup",areaPickup);


            const areaDropoff = dropoffPlace?.address_components?.find((component:any) => {
                for (let areaType of areaTypes) {
                    if (component.types.includes(areaType)) {
                    return true;
                    }
                }
                return false;
                });

       
        let updatedData:any = {
            pickup:{lat:pickUp?.geometry?.location.lat(),lng:pickUp?.geometry?.location.lng(),text:route.start_address, area:areaPickup?.long_name},
            dropoff:{lat:dropoffPlace?.geometry?.location.lat(),lng:dropoffPlace?.geometry?.location.lng(),text:route.end_address,area:areaDropoff?.long_name},
            zoneId:data.id
        }
        props.setData({response,updatedData})
        console.log("props setData",props);
        setEstimate(response.data);
    }

   

    useEffect(() => {
        if (!pickUp || !distance || !duration || !serviceId === undefined) {
            console.log(`incomplete ${pickUp} ${distance} ${duration} ${serviceId}`)
            return;
        }
        console.log('complete')
        loadEstimate();

    }, [pickUp, distance, duration, trafficDuration, serviceId]);

    useEffect(() => {
        refresh();
    }, [])

    const refresh = async () => {
        try {
            const response = await ApiService.get('service/public/list');
            const data: ServiceList[] = response.data.map((item: any) => ({ id: item.id, title: item.title }));
            setServiceId(data[0].id)
        } catch (err) {
            console.log('Failed to load fleet list', err);
        }
    }

    useEffect(() => {
        if (!pickUp || !dropoffPlace) {
            if (pickUp) {
                console.log("pickup",pickUp)
                setCenter(pickUp.geometry?.location);
                setZoom(18);
            }
            if (dropoffPlace) {
                setCenter(dropoffPlace.geometry?.location);
                setZoom(18);
            }
            setDirections(undefined);
            return;
        }
        const departureTime = add(new Date(), { minutes: 10, hours: 5 });
        directionsService?.route({
            origin: pickUp?.geometry?.location,
            destination: dropoffPlace?.geometry?.location,
            travelMode: google.maps.TravelMode.DRIVING,
            durationInTraffic: true,
            drivingOptions: { departureTime }
        }, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                setDirections(result);
                
                const route:any = result.routes[0].legs[0];
                if (!route) {
                    return;
                }

                console.log('ROUTE', route)
                setRoute(route)
               
                setDistance(route.distance.value);
                setDuration(route.duration_in_traffic ? route.duration_in_traffic.value : route.duration.value);

                const trafficTime = route.duration_in_traffic ? route.duration_in_traffic.value - route.duration.value : 0;
                setTrafficDuration(trafficTime > 0 ? trafficTime : 0);
            }
        })
    }, [pickUp, dropoffPlace]);

    const distanceText = (): string => {
        if (!distance) {
            return 'N/A';
        }
        return `${roundTo(distance / 1000)} km`;
    }

    const durationText = (): string => {
        if (!duration) {
            return 'N/A';
        }
        const epoch = new Date(0)
        const secondsAfterEpoch = new Date(duration * 1000)
        const durationObj = intervalToDuration({
            start: epoch,
            end: secondsAfterEpoch
        })
        return formatDuration(durationObj, { format: ['hours', 'minutes'] });
    }

    const trafficText = (): string => {
        if (!trafficDuration) {
            return 'N/A';
        }
        const epoch = new Date(0)
        const secondsAfterEpoch = new Date(trafficDuration * 1000)
        const durationObj = intervalToDuration({
            start: epoch,
            end: secondsAfterEpoch
        })
        return formatDuration(durationObj, { format: ['hours', 'minutes'] });
    }

    const estimateText = (): string => {
        if (!estimate || isNaN(estimate)) {
            return '...';
        }
        console.log("estimate",estimate)
        // const finalEstimate = estimate / subunit
        return `${currency}${estimate.toFixed(2)}`;

    }

    const roundTo = (val: number): number => {
        return Math.round((val + Number.EPSILON) * 100) / 100
    }

    return (
        <LoadScript googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`} libraries={MAP_LIBS}>
            <BookingFormSection {...props}>
                <>
                    <p>Please allow this page to retrieve your location or enter your location above.</p>
                    <h4>Please enter your dropOff location to complete your ride booking</h4>
                    <Autocomplete
                        ref={dropoffAutocompleteRef}
                        // restrictions={{ country: ['pak'] }}
                        fields={['geometry',"address_component","formatted_address"]}
                        className='inputshow'
                    >
                        <input className="form-control bg-white" placeholder="Dropoff" type="text" />
                    </Autocomplete>
                    {
                        theme === process.env.REACT_APP_TAXIS_PLUS_THEME ? <div className={(pickUp ? 'main-map' : '')}>
                            <div className={'input' + (pickUp ? 'show' : '')}>
                                <GoogleMap
                                    mapContainerStyle={{ width: '100%', height: 500 }}
                                    center={center}
                                    zoom={zoom}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                >
                                    {!directions && pickUp && pickUp.geometry && <Marker position={pickUp.geometry.location} />}
                                    {!directions && dropoffPlace && dropoffPlace.geometry && <Marker position={dropoffPlace.geometry.location} />}
                                    {directions && <DirectionsRenderer options={{ directions }} />}
                                    {directions && <TrafficLayer />}
                                </GoogleMap>
                            </div>
                        </div> :
                            <div className={'input' + (pickUp ? 'show' : '')}>
                                <GoogleMap
                                    options={{ styles: MapStyle }}
                                    mapContainerStyle={{ width: '100%', height: 500 }}
                                    center={center}
                                    zoom={zoom}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                >
                                    {!directions && pickUp && pickUp.geometry && <Marker position={pickUp.geometry.location} />}
                                    {!directions && dropoffPlace && dropoffPlace.geometry && <Marker position={dropoffPlace.geometry.location} />}
                                    {directions && <DirectionsRenderer options={{ directions }} />}
                                    {directions && <TrafficLayer />}
                                </GoogleMap>
                            </div>
                    }
                    <div className={'estimate' + (distance ? ' show' : '')}>
                        <div className="estimate-box hero">
                            <p>{estimateText()}</p>
                        </div>
                        <div className="estimate-box">
                            <h4>Distance</h4>
                            <p>{distanceText()} </p>
                        </div>
                        <div className="estimate-box">
                            <h4>Duration</h4>
                            <p>{durationText()}</p>
                        </div>
                        <div className="estimate-box">
                            <h4>Traffic</h4>
                            <p>{trafficText()}</p>
                        </div>
                    </div>
                    {theme === process.env.REACT_APP_COOL_RIDE_THEME ?
                        <input
                            value='Next'
                            type='submit'
                            className="button"
                        /> :
                        <input
                            value='Next'
                            type='submit'
                            className="texisPlusBtn"
                        />
                    }
                </>
            </BookingFormSection>
        </LoadScript>
    )
}