import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import '../src/css/style.css'
import * as serviceWorker from './serviceWorker';
import { UserBookingRegister } from "./Component/landing/register";
import Payment from "./Component/payment/payment";

const coolconnectFavicon = require(`./css/img/${process.env.REACT_APP_COOL_RIDE_FAVICON}`);
const taxisPlusFavicon = require(`./css/img/${process.env.REACT_APP_TAXIS_PLUS_FAVICON}`);

export const ThemeContext = React.createContext(process.env.REACT_APP_THEME);

export default function App() {

    const [theme, setTheme] = useState({ value: process.env.REACT_APP_THEME })
   
    const handleChange = (e: any) => {
        setTheme({ value: e.target.value })
    }

    return (
        <ThemeContext.Provider value={theme.value}>
            <div>
                {theme.value === process.env.REACT_APP_COOL_RIDE_THEME ?
                    <Helmet>
                        <title>{`${process.env.REACT_APP_COOL_RIDE_THEME}`}</title>
                        <link rel='icon' href={coolconnectFavicon} />
                        <style>{'body {background-color: black;font-family: Electrolize;color:#DCDCAA;}'}</style>
                    </Helmet>
                    :
                    <Helmet>
                        <title>{`${process.env.REACT_APP_TAXIS_PLUS_NAME}`}</title>
                        <link rel='icon' href={taxisPlusFavicon} />
                        <style>{'body {font-family: Dosis, sans-serif; background: #edff02; color: #000;}'}</style>
                    </Helmet>
                }
                {/* <select className="bg-white" value={theme.value} onChange={handleChange}>
                    <option value={process.env.REACT_APP_COOL_RIDE_THEME}>CoolRide Theme</option>
                    <option value={process.env.REACT_APP_TAXIS_PLUS_THEME}>TaxisPlus Theme</option>
                </select> */}
                <Router>
                    <>
                        <Switch>
                            <Route exact path='/' component={UserBookingRegister} />
                            <Route exact path='/payment' component={Payment} />
                        </Switch>
                    </>
                </Router>
            </div>
        </ThemeContext.Provider>
    );
}

ReactDOM.render(
   
         <App />,
    document.getElementById('root')
);
serviceWorker.unregister();
