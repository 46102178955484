import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./components/CheckoutForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

export default function Payment() {
    return (
        <Elements stripe={stripePromise}>
            <PaymentForm />
        </Elements>
    );
}
