import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { ThemeContext } from '../../..';
import { ApiService } from '../../../service/api.service';
import { BookingFormSection, BookingFormSectionProps } from '../section';
import { Alert } from 'reactstrap';
import { useHistory } from 'react-router';


export function PassengerForm(props: any) {
    const [disable, setDisable] = useState<boolean>(true);
    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [newPassenger, setNewPassenger] = useState<any>();
    const [passenger, setPassenger] = useState<any>();
    const [oldPassenger, setOldPassenger] = useState<any>();
    const [verficationNumber, setVerficationNumber] = useState<string>("");
    const [alert, setAlert] = useState<string>("");
    const history = useHistory();
    
    

    const theme = useContext(ThemeContext)
    const sendVerfication = async(e:any)=>{


        const response = await ApiService.post('receipt/verify/send-otp', {
           phone,
           name
        });
        // const response:any = {data:{
        //     passenger:{
        //         id:"ali",
        //         phone:"2123131321"
        //     }
        // }}
        
      
        if(response.data?.newPassenger){
            setNewPassenger(response.data?.newPassenger)
            setPassenger(response.data?.newPassenger)
        }
      

        if(response.data?.passenger){
           
            setOldPassenger(response.data?.passenger)
            setPassenger(response.data?.passenger)
        }

       

    }

    


    const matchVerfication = async()=>{


        if(verficationNumber == passenger?.verificationCode){
           
        let stateData = props.data
        console.log("state",stateData);
        stateData.passenger = passenger
        console.log(stateData);
            history.push({
                pathname: '/payment',
                // search: '?query=abc',
                state: { stateData:JSON.stringify(stateData)}
              })

        }
        else{
            setAlert("You have entered the wrong verfication code")
            setTimeout(function(){  setAlert("") }, 8000);
        }
       
    }
 
    function getValues(e:any){
        if(e.target.name === "name"){
            setName(e.target.value)
        }
        if(e.target.name === "phone"){
            setPhone(e.target.value)
        }
        if(e.target.name === "verficationNumber"){
            setVerficationNumber(e.target.value)
        }
        
      
    }


    useEffect(()=>{

        console.log("props",props.data)

    },[props.data])


    return (
        <BookingFormSection {...props}>
            <>
            {oldPassenger == undefined && newPassenger == undefined?<div>
                <h4>Please enter your name and phone number to complete your ride booking</h4>
                <input autoFocus onChange={getValues} name="name" className="form-control bg-white inputshow" placeholder="Name" type="text" />
                <input onChange={getValues} className="form-control bg-white inputshow" placeholder="Phone Number" name="phone" type="text" />
                {theme === process.env.REACT_APP_COOL_RIDE_THEME ?
                    <input
                        
                        value='Next'
                        type='submit'
                        className="button"
                        onClick={sendVerfication}
                    // disabled={disable}
                    /> :
                    <input
                        value='Next'
                        type='submit'
                        className="texisPlusBtn"
                        onClick={sendVerfication}
                    // disabled={disable}
                    />
                }

                </div>:<div>

                {
                        theme === process.env.REACT_APP_COOL_RIDE_THEME || theme === process.env.REACT_APP_TAXIS_PLUS_THEME ?<div>
                               <div className="row">
                                   <div className="col-12">
                                   {alert?<Alert color="danger">
        {alert}
      </Alert>:""}
                                   <h4>Please enter your verfication number sent to your phone </h4>
                                   <input autoFocus onChange={getValues} name="verficationNumber" className="form-control bg-white inputshow" style={{height:"20%"}} placeholder="4-digit Number" type="text" />
                                   { theme === process.env.REACT_APP_COOL_RIDE_THEME ?<button
                                   
                                        style={{    marginBottom: "80px"}}
                                        className="button"
                                        onClick={matchVerfication}
                    
                                   >Submit</button>:<button
                                   
                                   style={{ backgroundColor: "#ffffff",   marginBottom: "80px"}}
                                   className="button"
                                   onClick={matchVerfication}
               
                              >Submit</button>}
                                   </div>
                             
                                {oldPassenger?<div className="col-12">
                                
                                <div className="flex-Show" style={{margin: "55px",flexDirection: "column"}}>
                                <div className="row" >
                                    <div className="col-12"  style={{marginBottom:"30px"}}>

                                    { theme === process.env.REACT_APP_COOL_RIDE_THEME ?    <span style={{fontFamily: "Electrolize, sans-serif", lineHeight: "1.2",
    marginTop: "0",
    marginBottom: "20px",
    fontSize: "xx-large",
    color: "#dcdcaa" }} >PASSENGER DETAILS</span>:<span style={{fontFamily: "Electrolize, sans-serif", lineHeight: "1.2",
    marginTop: "0",
    marginBottom: "20px",
    fontSize: "xx-large",
    color: "rgb(0 0 0)" }} >PASSENGER DETAILS</span>}

                                    </div>
                                   
                               
                                    </div>

                                    <div className="row" >
                                    <div className="col-6">

                                    { theme === process.env.REACT_APP_COOL_RIDE_THEME ?  <span style={{fontFamily: "Electrolize, sans-serif", lineHeight: "1.2",
    marginTop: "0",
    marginBottom: "20px",
    color: "#dcdcaa" }}>NAME</span>:<span style={{fontFamily: "Electrolize, sans-serif", lineHeight: "1.2",
    marginTop: "0",
    marginBottom: "20px",
    color: "rgb(0 0 0)" }}>NAME</span>}

                                    </div>

                                    <div className="col-6">
                                    { theme === process.env.REACT_APP_COOL_RIDE_THEME ?    <span style={{fontFamily: "Electrolize, sans-serif", lineHeight: "1.2",fontWeight: 1000,fontSize:"x-large",
    marginTop: "0",
    marginBottom: "20px",
    color: "#dcdcaa" }}>{oldPassenger?.firstName}</span> : <span style={{fontFamily: "Electrolize, sans-serif", lineHeight: "1.2",fontWeight: 1000,fontSize:"x-large",
    marginTop: "0",
    marginBottom: "20px",
    color: "rgb(0 0 0)" }}>{oldPassenger?.firstName}</span>}
                                    </div>
                                  
                               
                                    </div>
                                    { theme === process.env.REACT_APP_COOL_RIDE_THEME ?     <hr style={{borderTop: "2px solid #dcdc9d"}}></hr>:
                                     <hr style={{borderTop: "2px solid rgb(0 0 0)"}}></hr>}


                                    <div className="row" >
                                    <div className="col-6">

                                    { theme === process.env.REACT_APP_COOL_RIDE_THEME ?      <span style={{fontFamily: "Electrolize, sans-serif", lineHeight: "1.2",
    marginTop: "0",
    marginBottom: "20px",
    color: "#dcdcaa" }}>PHONE</span>:
    <span style={{fontFamily: "Electrolize, sans-serif", lineHeight: "1.2",
    marginTop: "0",
    marginBottom: "20px",
    color: "rgb(0 0 0)" }}>PHONE</span>}

                                    </div>

                                    <div className="col-6">
                                   { theme === process.env.REACT_APP_COOL_RIDE_THEME ?  <span style={{fontFamily: "Electrolize, sans-serif", lineHeight: "1.2",fontWeight: 1000,fontSize:"x-large",
    marginTop: "0",
    marginBottom: "20px",
    color: "#dcdcaa" }}>{oldPassenger?.phone}</span> :<span style={{fontFamily: "Electrolize, sans-serif", lineHeight: "1.2",fontWeight: 1000,fontSize:"x-large",
    marginTop: "0",
    marginBottom: "20px",
    color: "rgb(0 0 0)" }}>{oldPassenger?.phone}</span>}
                                    </div>
                                  
                               
                                    </div>
                                    

                                    { theme === process.env.REACT_APP_COOL_RIDE_THEME ?     <hr style={{borderTop: "2px solid #dcdc9d"}}></hr>:
                                     <hr style={{borderTop: "2px solid rgb(0 0 0)"}}></hr>}

                                   

                                  
                              
                               
                            </div>
                                </div>:""}
                            </div>
                           
                        </div>
                        :""}
                    
                    </div>}
            </>
        </BookingFormSection>
    )
}